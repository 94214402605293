<template>
  <div class="view-box">
    <div class="view-box-title">客服电话情况</div>
    <div class="view-box-content" id="rightOneChart" style="padding-top: 24px;"></div>
  </div>
</template>

<script>
  import G2 from '@antv/g2'

  export default {
    name: 'rightOne',
    data () {
      return {
        data: [
          { name: '已处理', 月份: '六月.', 月均降雨量: 20 },
          { name: '已处理', 月份: '七月.', 月均降雨量: 24 },
          { name: '已处理', 月份: '八月.', 月均降雨量: 35 },
          { name: '已处理', 月份: '九月.', 月均降雨量: 12 },
          { name: '已处理', 月份: '十月.', 月均降雨量: 33 },
          { name: '已处理', 月份: '十一月.', 月均降雨量: 34 },
          { name: '已处理', 月份: '十二月.', 月均降雨量: 19 },
          { name: '未处理', 月份: '一月.', 月均降雨量: 88 },
          { name: '未处理', 月份: '二月.', 月均降雨量: 12 },
          { name: '未处理', 月份: '三月.', 月均降雨量: 31 },
          { name: '未处理', 月份: '四月.', 月均降雨量: 19 },
          { name: '未处理', 月份: '五月', 月均降雨量: 10 },
          { name: '未处理', 月份: '六月.', 月均降雨量: 14 },
          { name: '未处理', 月份: '七月.', 月均降雨量: 20 },
          { name: '未处理', 月份: '八月.', 月均降雨量: 10 },
          { name: '未处理', 月份: '九月.', 月均降雨量: 5 },
          { name: '未处理', 月份: '十月.', 月均降雨量: 20 },
          { name: '未处理', 月份: '十一月.', 月均降雨量: 30 },
          { name: '未处理', 月份: '十二月.', 月均降雨量: 70 },
          { name: '已处理', 月份: '一月.', 月均降雨量: 18 },
          { name: '已处理', 月份: '三月.', 月均降雨量: 69 },
          { name: '已处理', 月份: '四月.', 月均降雨量: 41 },
          { name: '已处理', 月份: '五月', 月均降雨量: 17 },
          ],
        chart: null
      }

    },
    mounted () {
      this.initChart()
    },
    methods: {
      initChart () {
        const chart = new G2.Chart({
          container: 'rightOneChart',
          /*autoFit: true,*/
          height: 180,
          width: 594,
          padding: 'auto',
          lineWidth: 1,
        })

        chart.source(this.data)
        chart.scale('月均降雨量', {
          nice: true
        })
        chart.tooltip({
          showMarkers: false,
          shared: true
        })
        chart.axis('月份', {
          label: {
            textStyle: {
              textAlign: 'center', // 文本对齐方向，可取值为： start middle end
              fill: '#0f769d', // 文本的颜色
              fontSize: '12', // 文本大小
              fontWeight: 'bold', // 文本粗细
              textBaseline: 'top' // 文本基准线，可取 top middle bottom，默认为middle
            }, // 文本样式，支持回调
          }
        });
        chart.axis(
          '月均降雨量', {
            label: {
              textStyle: {
                textAlign: 'center', // 文本对齐方向，可取值为： start middle end
                fill: '#0f769d', // 文本的颜色
                fontSize: '12', // 文本大小
                fontWeight: 'bold', // 文本粗细
                textBaseline: 'top' // 文本基准线，可取 top middle bottom，默认为middle
              }, // 文本样式，支持回调
            },
            tickLine: {
              lineWidth: 1, // 刻度线宽
              stroke: '#0f769d', // 刻度线的颜色
              strokeOpacity: 0.5, // 刻度线颜色的透明度
              length: -590, // 刻度线的长度，可以为负值（表示反方向渲染）
            /*  alignWithLabel:true//设为负值则显示为category数据类型特有的样式*/
            }
          }
        )
        chart
          .interval()
          .position('月份*月均降雨量')
          .color('name', ['#72fff4', '#00c0ff'])
          .adjust([
            {
              type: 'dodge',
              marginRatio: 0
            }
          ])

        // chart.interaction('active-region')
        this.chart = chart
        this.chart.render()
      }
    }
  }
</script>

<style scoped>
</style>