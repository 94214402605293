<template>
  <div id="data-view">
    <dv-full-screen-container>
      <Header></Header>
      <div class="content">
        <a-row style="padding: 10px;">
          <a-col :span="8">
            <Left></Left>
          </a-col>
          <a-col :span="8" style="text-align: center">
            <a-row>
              <div style="width: 100%;text-align: center;margin-top: 50px;padding-left: 10px;padding-right: 10px;">
                <dv-border-box-7 :color="['#071d46', '#1957dc']" :reverse="true" class="data-card" style="margin-left: 0px;">
                  <div class="content">
                    <span class="card-title">累计巡检次数</span><br>
                    <span class="card-value">175<span class="unit">次</span></span><br>
                    <span class="card-footer">今日巡检<span class="date-count">18</span>次</span>
                  </div>
                </dv-border-box-7>
                <dv-border-box-7 :color="['#071d46', '#1957dc']" :reverse="true" class="data-card">
                  <div class="content">
                    <span class="card-title">累计处理工单</span><br>
                    <span class="card-value">75<span class="unit">次</span></span><br>
                    <span class="card-footer">今日工单<span class="date-count">20</span>个</span>
                  </div>
                </dv-border-box-7>
                <dv-border-box-7 :color="['#071d46', '#1957dc']" :reverse="true" class="data-card">
                  <div class="content">
                    <span class="card-title">累计接收客服电话</span><br>
                    <span class="card-value">109<span class="unit">次</span></span><br>
                    <span class="card-footer">今日电话<span class="date-count">30</span>个</span>
                  </div>
                </dv-border-box-7>
                <dv-border-box-7 :color="['#071d46', '#1957dc']" :reverse="true" class="data-card">
                  <div class="content">
                    <span class="card-title">累计车辆拉运</span><br>
                    <span class="card-value">180<span class="unit">次</span></span><br>
                    <span class="card-footer">今日拉运<span class="date-count">34</span>趟</span>
                  </div>
                </dv-border-box-7>
              </div>
            </a-row>
            <a-row class="center-map">
              <center-map></center-map>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row>
              <a-col span="24">
                <right-one></right-one>
              </a-col>
            </a-row>
            <a-row>
              <a-col span="24">
                <right-two></right-two>
              </a-col>
            </a-row>
            <a-row>
              <a-col span="24">
                <right-three></right-three>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
    </dv-full-screen-container>
  </div>
</template>
<script>
  import Header from './Header'
  import Left from '../ningxiascreen/Left/Index'
  import RightOne from './rightOne'
  import RightTwo from './rightTwo'
  import CenterMap from './CenterMap'
  import RightThree from './rightThree'

  export default {
    components: { RightThree, RightTwo, RightOne, Header, Left, CenterMap }
  }
</script>
<style lang="less">
  #data-view {
    width: 100%;
    color: #a2d3ff;
    height: 100%;
    background-color: #154f9e;

    #dv-full-screen-container {
      background-image: url('../screen-home/img/bg.png');
      background-size: 100% 100%;
      box-shadow: 0 0 3px blue;
      display: flex;
      flex-direction: column;
    }
  }

  .date-count {
    font-weight: bolder;
    font-size: larger;
    color: white;
  }

  .view-box {
    margin: 10px;

    .view-box-title {
      font-size: 20px;
      font-weight: bold;
      color: #7fd8ec;
    }

    .view-box-content {
      padding: 8px;
      margin-top: 10px;
      border: 2px solid #4e7bce;
      border-radius: 6px;
    }
  }

  .center-map {
    margin: 14px;
    border: 0px solid #123473;
    border-radius: 8px;
    overflow: hidden;
    width: 606px;
    height: 766px;
  }
  .l7-control-logo {
    display: none;
  }
  .data-card {
    width: 24%;
    height: auto;
    float: left;
    margin-left: 1.33%;
    text-align: center;

    .content {
      padding: 15px;

      .card-title {
        font-size: 14px;
        font-weight: bold;
        color: #16bbc3;
      }

      .card-value {
        font-size: 25px;
        font-weight: bold;
        line-height: 36px;

        .unit {
          font-weight: normal;
          font-size: 12px;
        }
      }

      .card-footer {
        font-weight: bold;
        font-size: 12px;
        color: #3a97a0;
      }
    }
  }

</style>