<template>
  <div id="top-header">
    <a-row style="width: 100%">
      <a-col :span="7">
        <a-row>
          <a-col :span="6" @click="click('sy')" style="cursor: pointer">
            <dv-border-box-12 class="link-card"><span>首页</span></dv-border-box-12>
          </a-col>
          <a-col :span="6" @click="click('jc')" style="cursor: pointer">
            <dv-border-box-12 class="link-card">设备检查</dv-border-box-12>
          </a-col>
          <a-col :span="6" @click="click('gl')" style="cursor: pointer">
            <dv-border-box-12 class="link-card">巡检管理</dv-border-box-12>
          </a-col>
          <a-col :span="6" @click="click('cl')" style="cursor: pointer">
            <dv-border-box-12 class="link-card">车辆管理</dv-border-box-12>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="10">
        <div class="top-title">
          <dv-decoration-5 class="header-center-decoration"/>
          <span class="center-title">宁夏自治区污水处理大数据平台</span>
        </div>
      </a-col>
      <a-col :span="7">
        <a-row>
          <a-col :span="6" @click="click('zhdt')" style="cursor: pointer">
            <dv-border-box-12 class="link-card">综合地图</dv-border-box-12>
          </a-col>
          <a-col :span="6" @click="click('gdcl')" style="cursor: pointer">
            <dv-border-box-12 class="link-card">工单处理</dv-border-box-12>
          </a-col>
          <a-col :span="6" @click="click('dhjl')" style="cursor: pointer">
            <dv-border-box-12 class="link-card">电话记录</dv-border-box-12>
          </a-col>
          <a-col :span="6" @click="click('3d')" style="cursor: pointer">
            <dv-border-box-12 class="link-card">工艺组态</dv-border-box-12>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
  export default {
    name: 'TopHeader',
    data () {
      return {
        cardColor: ['#3f96a5'],
        backgroundColor: '#071531'
      }
    },
    methods: {
      click (val) {
        if (val === 'sy') {
          this.$router.push('/user/workplace')
        } else if (val === 'jc') {
          this.$router.push('/xj/inquire')
        } else if (val === 'gl') {
          this.$router.push('/xj/myInspection')
        } else if (val === 'cl') {
          this.$router.push('/car/record/mygiven')
        } else if (val === '3d') {
          // this.$router.push('/example/3d')
          window.open('/equipment2/technology/3d' + '?pointId=290')
        } else if (val === 'gdcl') {
          this.$router.push('/ticket/all')
        } else if (val === 'dhjl') {
          this.$router.push('/damagedreport/report')
        } else if (val === 'zhdt') {
          this.$router.push('/amap/composite/2')
        }

      }
    }
  }
</script>

<style lang="less">
  .top-title {
    position: absolute;
    width: 100%;
    top: 44px;
    .center-title {
      text-align: center;
      left: 194px;
      font-size: 34px;
      color: white;
      position: absolute;
      top: -16px;
      font-weight: bold;
    }
  }

  .header-center-decoration {
    width: 100%;
    height: 60px;
    margin: 30px auto 0 auto;
  }

  .link-card {
    box-shadow: none;
    margin: 40px 20px 20px 20px;
    text-align: center;
    line-height: 50px;
    width: 100px;
    height: 50px;
  }
</style>