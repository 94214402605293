<template>
  <div :id="id"></div>
</template>
<script>
  import G2 from '@antv/g2'

  export default {
    name: 'Pie',
    props: {
      data: Array,
      id: String,
      classifyDate: String
    },
    data() {
      return {
        color: []
      }
    },
    watch: {
      data(val) {
        this.data = val
        this.chart.source(this.data)
        this.chart.guide().clear()// 清理guide
        this.chart.repaint()
      }
    },
    mounted() {
      this.initChart()
    },
    methods: {
      initChart() {
        // 可以通过调整这个数值控制分割空白处的间距，0-1 之间的数值
        const sliceNumber = 0.01

        // 自定义 other 的图形，增加两条线
        G2.Shape.registerShape('interval', 'sliceShape', {
          draw(cfg, container) {
            const points = cfg.points
            let path = []
            path.push(['M', points[0].x, points[0].y])
            path.push(['L', points[1].x, points[1].y - sliceNumber])
            path.push(['L', points[2].x, points[2].y - sliceNumber])
            path.push(['L', points[3].x, points[3].y])
            path.push('Z')
            path = this.parsePath(path)
            return container.addShape('path', {
              attrs: {
                fill: cfg.color,
                path
              }
            })
          }
        })

        const chart = new G2.Chart({
          container: this.id,
          forceFit: true,
          height: 140,
          padding: 'auto'
        })
        chart.source(this.data)
        chart.coord('theta', {
          innerRadius: 0.75
        })
        chart.tooltip({
          showTitle: false
        })
        chart.guide().html({
          position: ['50%', '50%'],
          html: '<div class="g2-guide-html" style="text-align: center;line-height: 30px">' +
            '<p class="value">' + this.classifyDate + '</p></div>'
        })
        chart.legend(false)
        chart.intervalStack().position('value').color('type', ['#1c65ff', '#4ee1ff', '#caffc9', '#8fd6ff', '#66ffb7'])
          .opacity(1)
          .label('value', {
            offset: -5,
            textStyle: {
              fill: 'white',
              fontSize: 12,
              shadowBlur: 2,
              shadowColor: 'rgba(0, 0, 0, .45)'
            },
            rotate: 0,
            autoRotate: false,
            formatter: (text, item) => {
              return text
            }
          })
        // chart.intervalStack()
        //   .position('value')
        //   .color('type')
        //   .shape('sliceShape')
        chart.on('interval:click', ev => {
          const data = ev.data
          if (data) {
            const name = data._origin['type']
            this.$emit('clickPie', name)
          }
        })
        this.chart = chart
        this.chart.render()
      }
    }
  }
</script>
<style>
</style>