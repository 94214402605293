<template>
  <div class="view-box">
    <div class="view-box-title">宁夏回族自治区污水处理的概况</div>
    <div class="view-box-content" style="padding: 12px;text-indent: 2em;line-height: 24px;">
      宁夏回族自治区农村改厕及污水处理特许经营项目涉及掌政镇、牙湖乡、通贵乡等3个乡镇共约11467户农村居民，
      敷设管线长154.11km,新建50m³集污池76个，100m³集污池41个，一体化分散式处理站299座。从源头上解决问题，
      做到农村污水集中收集、统一处理，达标排放，是一项实现生活污水的统一收集和处理，大幅减少污染物乱排问题，极大的改善村庄人居环境，
      关系社会主义新农村建设的民生工程。
    </div>
  </div>
</template>
<script>
  export default {
    name: 'LeftOne',
    components: {}
  }
</script>
<style scoped>
</style>