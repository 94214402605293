<template>
  <div id="groupBar"></div>
</template>
<script>
  import G2 from '@antv/g2'

  export default {
    name: 'bar',
    props: {
      data: Array
    },
    data () {
      return {}
    },
    watch: {
      data (val) {
        this.data = val
        this.chart.source(this.data)
        this.chart.guide().clear()// 清理guide
        this.chart.repaint()
      }
    },
    mounted () {
      this.initChart()
    },
    methods: {
      initChart () {
        const chart = new G2.Chart({
          container: 'groupBar',
          height: 250,
          forceFit: true,
          width: 594,
          padding: [30, 10, 70, 20],
          lineWidth: 1
        })
        chart.axis('月份', {
          label: {
            textStyle: {
              textAlign: 'center', // 文本对齐方向，可取值为： start middle end
              fill: '#0f769d', // 文本的颜色
              fontSize: '12', // 文本大小
              fontWeight: 'bold', // 文本粗细
              textBaseline: 'top' // 文本基准线，可取 top middle bottom，默认为middle
            }, // 文本样式，支持回调
          }
        });
        chart.axis(
          '月均降雨量', {
            tickLine: {
              lineWidth: 1, // 刻度线宽
              stroke: '#0f769d', // 刻度线的颜色
              strokeOpacity: 0.5, // 刻度线颜色的透明度
              length: -590, // 刻度线的长度，可以为负值（表示反方向渲染）
              /*  alignWithLabel:true//设为负值则显示为category数据类型特有的样式*/
            },
            label: {
              textStyle: {
                textAlign: 'center', // 文本对齐方向，可取值为： start middle end
                fill: '#0f769d', // 文本的颜色
                fontSize: '12', // 文本大小
                fontWeight: 'bold', // 文本粗细
                textBaseline: 'top' // 文本基准线，可取 top middle bottom，默认为middle
              }, // 文本样式，支持回调
            },
          }
        )
        chart.source(this.data)
        chart.interval()
          .position('月份*月均降雨量')
          .color('name', ['#26c0d0', '#1696ff', '#1655c7'])
          .adjust([{
            type: 'dodge',
            marginRatio: 1 / 32
          }])
        this.chart = chart
        this.chart.render()
      }
    }
  }
</script>
<style>
</style>