<template>
  <div>
    <LeftOne></LeftOne>
    <LeftTwo></LeftTwo>
    <LeftThree></LeftThree>
  </div>
</template>
<script>
  import LeftOne from './LeftOne'
  import LeftTwo from './LeftTwo'
  import LeftThree from './LeftThree'

  export default {
    components: { LeftOne, LeftTwo, LeftThree },
    data () {
      return {}
    },
    created () {

    },
    methods: {}
  }
</script>
<style>

</style>